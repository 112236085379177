/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/item3.png'
import IMG2 from '../../assets/item4.png'
import IMG3 from '../../assets/item5.png'
import IMG4 from '../../assets/item6.png'
import IMG5 from '../../assets/portfolio4.png'
import IMG6 from '../../assets/portfolio4.jpg'

const data = [
  {
    id:1,
    image:IMG1,
    title: 'BMARPC',
    github:'https://github.com/saiful199324',
    demo:'https://www.abdurroufcollege.ac.bd/'
  },
  {
    id:2,
    image:IMG2,
    title: 'RCPSC',
    github:'https://github.com/saiful199324',
    demo:'http://www.rcpsc.edu.bd/'
  },
  {
    id:3,
    image:IMG3,
    title: 'DCC',
    github:'https://github.com/saiful199324',
    demo:'https://www.dcc.edu.bd/'
  },
  {
    id:4,
    image:IMG4,
    title: 'ACPS',
    github:'https://github.com/saiful199324',
    demo:'https://www.acps.edu.bd/'
  },
  {
    id:5,
    image:IMG5,
    title: 'DCGPSC',
    github:'https://github.com/saiful199324',
    demo:'http://dcgpsc.edu.bd/'
  },
  {
    id:6,
    image:IMG6,
    title: 'Pranisheba Shop',
    github:'https://github.com/saiful199324',
    demo:'https://www.pranishebashop.com.bd/'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
          {
            data.map(({id,image, title, github, demo})=>{
              return(
                  <article key={id} className='portfolio__item'>
                  <div className="portfolio__item-image">
                    <img src={image} alt={title}/>
                  </div>
                  <h3>{title}</h3>
                  <div className='portfolio__item-cta'>
                      <a href={github} className='btn' target='_blank'>GitHub</a>
                      <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                  </div>
                  </article>
              )
            })
          }
      </div>
      <h2 style={{marginTop: "5rem"}}><a href='http://codewithsaif2475.com/my-portfolio/' target='_blank'>More About Me...</a></h2>
    </section>
  )
}

export default Portfolio