/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'         
import {FiInstagram} from 'react-icons/fi'         
import {IoLogoTwitter} from 'react-icons/io'         

const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Saiful2475</a>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href='https://m.facebook.com/mdsaifulislam.sim' target='_blank'><FaFacebookF/></a>
        <a href='https://www.instagram.com/saiful241993/' target='_blank'><FiInstagram/></a>
        <a href='https://twitter.com/Saiful2493?t=M2bweOSO_Fwemp5QWvi2ZQ&s=09' target='_blank'><IoLogoTwitter/></a>
      </div>

      <div className='footer__copyright'>
        <small>&#169;2022 Md. Saiful Islam. All rights reserved</small>
      </div>



      
    </footer>
  )
}

export default Footer