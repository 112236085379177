/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import './header.css'
import CTA  from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  
  return (
    <header id='home'>
      <div className="container header__container">
        
        <h5>Hello I'm</h5>
        <h1>Md. Saiful Islam</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA></CTA>
        <HeaderSocial></HeaderSocial>

        <div className="me">
          <img src={ME} alt="me"/>
        </div>
        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header