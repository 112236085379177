import React from 'react'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import './about.css'

const About = () => {
  return (
    
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className='container about_container'>
        <div className='about__me'>
            <div className="about__me-image">
              <img src={ME} alt="About" />
            </div>
          </div>
          
          <div className='about__content'>
              <div className='about__cards'>
              <article className='about__card'>
                  <FaAward className='about__icon'/>
                  <h5>Experience</h5>
                  <small>4+ Years Working</small>
                </article>
                <article className='about__card'>
                  <FiUsers className='about__icon'/>
                  <h5>Clients</h5>
                  <small>20+ Worldwide</small>
                </article>
                <article className='about__card'>
                  <VscFolderLibrary className='about__icon'/>
                  <h5>Projects</h5>
                  <small>20+ Completed</small>
                </article>

                
          

              </div>
              <p>
              Hello! Greetings to all. My name is Md. Saiful Islam, and I am able to provide you your professional Website and Web Application services. I'm very passionate to my Website Designing and Development Skills and always try to the job most professionally and looking forward to execute and implement your great ideas, Thoughts and goals.
              </p>

              <a href='#contact' className='btn btn-primary'>Let's Talk</a>
              
          </div>

          
          
          
          
          
        
      </div>
      
    </section>
  )
}

export default About