/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/saiful199324/' target="_blank"><BsLinkedin/></a>
        <a href='https://github.com/saiful199324' target="_blank"><FaGithub/></a>
        <a href='https://dribbble.com/saiful2475' target="_blank"><FiDribbble/></a>
    </div>
  )
}

export default HeaderSocials