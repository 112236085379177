import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I offer?</h5>
      <h2>Services</h2>
      <div className="container services__containter">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Clean Code.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Dynamic.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Responsive.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>User Friendly.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Creative.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Easy to maintain.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Efficient.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Consistent.</p>
            </li>
          </ul>
        </article>
        {/* End of UI/UX */}

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Good Web Design.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Web Content.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Quick and user-friendly navigation.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Loading Speed.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Web Compatibility.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Mobile Compatibility.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Well Planned Information Architecture.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Cross Browser Compatibility.</p>
            </li>
            
          </ul>
        </article>
          {/* End of Web Development */}

          <article className="service">
          <div className="service__head">
            <h3>Digital Marketing</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Search engine optimization (SEO).</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Pay-per-click (PPC) advertising.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Web design.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Content marketing.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Social media marketing.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Email marketing.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Mobile Marketing.</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Online Newsletter Marketing.</p>
            </li>
          </ul>
        </article>
          {/* End of Web Development */}
      </div>
    </section>
  )
}

export default Services