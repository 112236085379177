import React from 'react'
import {Helmet} from "react-helmet";
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

const App = () => {
  return (
      <>
      <Helmet>
        <title>Portfolio Md. Saiful Islam</title>
        <meta name='description' content='HI! this is Md. Saiful Islam'/>
        <meta name='keywords' content='portfolio,portfolio provider,school Management,School, College, University, Education,DCGPSC Result, Class Room, DCGPSC Exam, DCGPSC Notice, DCGPSC Routine, Dhaka cantonment Girls'/>
      </Helmet>
        <Header/>
        <Nav/>
        <About/>
        <Experience/>
        <Services/>
        <Portfolio/>
        <Testimonials/>
        <Contact/>
        <Footer/>
      
      <a
        href="https://wa.me/8801673546262"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      
      </>
    

  )
}

export default App